import { AxiosError } from 'axios'

import {
  ErrorResponse,
  HttpStatus,
  RequestInterceptor,
  ResponseInterceptor,
} from '@/app/types/api/config'
import { GtmEvent } from '@/app/types/app/event'
import { RouteName } from '@/app/types/app/routes'
import { StoreCommit } from '@/app/types/store/store'

import cookies from '@/plugins/cookies'
import i18n from '@/plugins/i18n'
import Store from '@/store'

import ErrorResponseInterceptorNotify from '@/app/api/factories/ErrorResponseInterceptorNotify'
import ClientResponseError from '@/app/ClientResponseError'
import env from '@/app/env'

import router from '@/router'

enum ServerErrors {
  Standard = 'An error occurred. Refresh page and try again',
}

const getToken = () => {
  const token = cookies.get(env.cookieName)

  return token ? 'Bearer ' + token : ''
}

function getErrorMessages(error: AxiosError<ErrorResponse>): string[] {
  const message = error.response?.data?.message ?? ServerErrors.Standard
  const errors = error.response?.data?.errors

  switch (error?.response?.status) {
    case HttpStatus.Unauthorized:
      cookies.remove(env.cookieName, '/', env.domain)
      window.dataLayer?.push({ event: GtmEvent.Logout })
      router.push({ name: RouteName.Login })

      return [message]
    case HttpStatus.UnprocessableEntity:
      return errors ? Object.values(errors).flat() : [message]
    case HttpStatus.ServiceUnavailable:
      return [i18n.global.t('errors.serviceUnavailable')]
    default:
      return [message]
  }
}

const requestInterceptors: RequestInterceptor = {
  onSuccess(config) {
    const noToken = !config?.headers?.Authorization

    if (config.headers && noToken) {
      config.headers['Authorization'] = getToken()
    }

    return config
  },
  onError(error) {
    return Promise.reject(error)
  },
}

function setMaintenanceStatus(responseStatus: HttpStatus | undefined) {
  if (responseStatus === HttpStatus.ServiceUnavailable) {
    Store.commit(StoreCommit.ChangeServerStatus, responseStatus)
  }
}

const responseInterceptor: ResponseInterceptor = {
  onSuccess(response) {
    return response
  },
  onError(error: AxiosError<ErrorResponse>): Promise<ClientResponseError> {
    const responseStatus: HttpStatus | undefined = error.response?.status

    setMaintenanceStatus(responseStatus)

    const messages = getErrorMessages(error)

    const notify = new ErrorResponseInterceptorNotify(
      i18n.global.t,
      error,
      messages,
    )

    notify.create()

    return Promise.reject(
      new ClientResponseError({
        name: error.name,
        message: error.message,
        status: responseStatus,
        errors: error.response?.data?.errors ?? null,
        messages,
        notify: notify.options,
      }),
    )
  },
}

export default {
  baseURL: env.baseURL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: getToken(),
  },
  timeout: +env.axiosTimeout,
  requestInterceptors,
  responseInterceptor,
}
