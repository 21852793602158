import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'

export type BackendResponse<T> = Promise<AxiosResponse<T>>

export interface ErrorResponse {
  message: string
  errors?: Record<string, string[]>
}

export interface RequestInterceptor {
  onSuccess: (
    requestConfig: InternalAxiosRequestConfig,
  ) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>
  onError: (error: any) => any
}

export interface ResponseInterceptor {
  onSuccess: (response: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>
  onError: (error: any) => any
}

export enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  ServiceUnavailable = 503,
}
